import React from 'react';
import '../App.css';
import { BrowserRouter as Router, Route, Link } from "react-router-dom"
import ContactSvg from '../undraw_contact_us_15o2.svg'
import WaveSvg from '../wave.svg';
import Investment from '../undraw_investment_xv9d-2.svg';
function Footer() { 
  return (
      <div className="App-footer">
        <img className="footerSvgDivider2" src={WaveSvg} />
    <div className="footerTextContainer">
      <Link to="/">
<div class="blobContainer">
  <div class="blobBox">
    <div class="blobSpinContainer">
      <div class="blobShape">
        <div class="blobBd">
      <h1 className="footerTitle">
      AGER
      </h1>
      </div>
      </div>
     </div>
  </div>
</div>
      </Link>
      
      {/*<img className="footerSvgContact" src={Investment} />*/}
      </div>
      </div>
  );
}

export default Footer;
