import React from 'react';
import '../App.css';
import { BrowserRouter as Router, Route, Link } from "react-router-dom"

function Header() {
  return (
      <div className="App-header">
      <div className="headerContainer">

        <Link to='/'>
  <div class="headerBlobBox">
    <div class="blobSpinContainer">
      <div class="blobShape">
        <div class="blobBd headerBlob">
      <h1 className="headerTitle">
      AGER
      </h1>
      </div>
      </div>
     </div>
  </div>
      </Link>
      {/*
      <div className="logoContainer">
      <h2 className="logoTitle">
        <Link to='/'>
            Ager
        </Link>
      </h2>
      </div>
      <div className="pageLink">
      <h3 className="linkTitle">
        <Link to='/'>
            Home
        </Link>
      </h3>
      <h3 className="linkTitle">
        <Link to='/whitepaper'>
         Whitepaper
        </Link>
      </h3>
      <h3 className="linkTitle">
        <Link to='/contact'>
         Contact
        </Link>
      </h3>
      </div>*/}
      </div>
        <svg className="headerWave" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" stroke="none" shapeRendering="auto" viewBox="0 0 1440 320"><path fill="#2C2C2EFF" fillOpacity="1" d="M0,224L48,202.7C96,181,192,139,288,133.3C384,128,480,160,576,149.3C672,139,768,85,864,101.3C960,117,1056,203,1152,229.3C1248,256,1344,224,1392,208L1440,192L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path></svg>
      </div>
  );
}

export default Header;
