import React from 'react';
import './App.css';
import MainCard from './components/MainCard.js';
import WorkCard from './components/WorkCard.js';
import Header from './components/Header.js';
import rotorImg from './rotor.png';
import { BrowserRouter as Router, Route, Link } from "react-router-dom"
import Whitepaper from './pages/Whitepaper.js';
import Culture from './pages/Culture.js';
import Contact from './pages/Contact.js';
import Home from './pages/Home.js';
import FAB from './components/FAB.js';

function App() {
  return (
    <Router>
     <div className="App">
        <Route path='/' exact component={Home} />
        <Route path='/whitepaper' component={Whitepaper} />
        <Route path='/culture' component={Culture} />
        <Route path='/contact' component={Contact} />
      <FAB />
      {/*
         <nav class="FabContainer"  > 
    <Link to='/whitepaper' class="FabButtons" tooltip="Whitepaper">
      <div className="FabIcon">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M15 2v5h5v15h-16v-20h11zm1-2h-14v24h20v-18l-6-6z"/></svg>
      </div>
      </Link>
    <Link to='/contact' class="FabButtons" tooltip="Contact">
      <div className="FabIcon">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418 4.72-8.912 1.251-13.678-3.732-13.678-5.082 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09 4.418-3.073.71-3.188 2.236-3.178 4.904l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z"/></svg>
    </div>
      </Link>
    <Link to='/' class="FabButtons" tooltip="Home">
      <div className="FabIcon">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M6 6h-6v-6h6v6zm9-6h-6v6h6v-6zm9 0h-6v6h6v-6zm-18 9h-6v6h6v-6zm9 0h-6v6h6v-6zm9 0h-6v6h6v-6zm-18 9h-6v6h6v-6zm9 0h-6v6h6v-6zm9 0h-6v6h6v-6z"/></svg>
    </div>
    </Link>
    <Link to='/' class="FabButtons" tooltip="Menu" >
      <div className="FabIcon">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 1h24v2h-24v-2zm12 7h12v-2h-12v2zm-12 5h24v-2h-24v2zm12 5h12v-2h-12v2zm-12 5h24v-2h-24v2z"/></svg>
    </div>
      </Link>
  </nav>
*/}
     </div>
    </Router>
  );
}

export default App;
